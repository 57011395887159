"use client"

// TODO: This feels bad
import {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useDeferredValue,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react"
import clsx from "clsx"
import { createPortal } from "react-dom"

import { XSmall } from "styles/Type"

const Tooltip = ({ id, children, variant }) => {
  const ref = useRef<HTMLElement>()
  const [mounted, setMounted] = useState(false)
  const [coords, setCoords] = useState([])
  const [flipped, setFlipped] = useState(false)

  useEffect(() => {
    let awaitingIdle = false
    const mouseFollower = (e) => {
      awaitingIdle = false
      setCoords([e.clientX, e.clientY])
      setFlipped(e.clientX > window.innerWidth - 300)
    }
    const mouseFollowerCaller = (e) => {
      if (awaitingIdle) {
        return
      }
      awaitingIdle = true
      requestAnimationFrame(() => mouseFollower(e))
    }

    document.addEventListener("mousemove", mouseFollowerCaller)
    return () => {
      document.removeEventListener("mousemove", mouseFollowerCaller)
    }
  }, [])

  useEffect(() => {
    ref.current = document.getElementById("tooltip-root")
    setMounted(true)
  }, [])

  const TooltipContent = useMemo(() => {
    return (
      <div
        role="tooltip"
        id={id}
        className={clsx(
          "fixed top-0 max-w-[320px] w-auto left-0 z-40 z-30py-2 p-3  pointer-events-none animate-fade-in",
          variant === "primary" ? "bg-rodeo text-black" : "bg-black text-white",
          variant === "dark" ? "bg-charcoal text-white" : ""
        )}
        style={{
          transform: `translate(${coords
            .map((c) => `${c}px`)
            .join(",")}) translate(${flipped ? "-100%" : "20px"}, -50%) ${
            flipped ? "translateX(-20px)" : ""
          }`,
        }}
      >
        <div
          className={clsx(
            "absolute top-1/2 -translate-y-1/2 h-3 w-3 rotate-45",
            flipped ? "right-0 translate-x-1/2" : "left-0 -translate-x-1/2",
            variant === "primary" ? "bg-rodeo" : "bg-black",
            variant === "dark" ? "bg-charcoal" : ""
          )}
        />
        {children}
      </div>
    )
  }, [children, coords, flipped, id])

  return useDeferredValue(
    mounted && coords.length ? createPortal(TooltipContent, ref.current) : null
  )
}

export type TooltipProps = {
  tooltipTitle?: string
  tooltip: ReactNode
  disabled?: boolean
  children: ReactElement
  variant?: "primary" | "secondary" | "dark"
}

export const TooltipWrapper: FC<TooltipProps> = ({
  tooltipTitle,
  tooltip,
  disabled = false,
  children,
  variant = "primary",
}) => {
  const id = useId()
  const [isVisible, setIsVisible] = useState(false)
  const onMouseOver = useCallback<MouseEventHandler>(() => {
    setIsVisible(true)
  }, [])
  const onMouseOut = useCallback<MouseEventHandler>(() => {
    setIsVisible(false)
  }, [])

  useEffect(() => {
    if (!isVisible) {
      return () => {}
    }
    const keyListener = (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return
      }
      setIsVisible(false)
    }
    document.addEventListener("keydown", keyListener)
    return () => {
      document.removeEventListener("keydown", keyListener)
    }
  }, [isVisible])

  if (disabled) {
    return children
  }

  const child = Children.only(children)
  const props: HTMLAttributes<HTMLElement> = {
    onMouseOver,
    onMouseOut,
  }
  // TODO: Trigger on focus
  if (isVisible) {
    props["aria-describedby"] = id
  }
  const Body = cloneElement(child, props)
  return isVisible ? (
    <>
      {Body}
      <Tooltip id={id} variant={variant}>
        {tooltipTitle ? (
          <>
            <XSmall className="font-medium" as="div">
              {tooltipTitle}
            </XSmall>
            <XSmall as="div">{tooltip}</XSmall>
          </>
        ) : (
          <XSmall as="div" className="font-medium">
            {tooltip}
          </XSmall>
        )}
      </Tooltip>
    </>
  ) : (
    Body
  )
}
export default TooltipWrapper
